import React, { useState } from "react";
import { useFormik } from "formik";
import { roles, levelOfCommitment, skillRequirement } from "../../../utils/constants";
import { OUTSOURCING_FOR_EMPLOYERS_URL } from "../../../config";
import { OutsourcingInput, InputSelect, OutsourcingButton, Toast } from "../../../components";
import { forEmployersSchema } from "./validation";
import { DropdownIcon } from "../../../assets/svg";
import style from "../styles.module.css";
import styles from "./styles.module.css";
import exists from "../outsourcingCommunity/querysearch";

const OutsourcingForEmployers = () => {
  const [displayToast, setDisplayToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastDetails, setToastDetails] = useState({ toastState: "", message: "" });
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      noOfEmployees: "",
      role: "",
      levelOfCommitment: "",
      startDate: "",
      skillRequirement: "",
      companyName: "",
      phoneNumber: "",
      companyWebsite: "",
    },
    validationSchema: forEmployersSchema,
    onSubmit: () => {
      handleSubmitForm();
    },
  });

  const { values, errors, handleChange, resetForm, handleSubmit, touched, handleBlur } = formik;

  async function handleSubmitForm() {
    setLoading(true);
    const { info, isExist } = await exists(
      values.email,
      values.phoneNumber,
      OUTSOURCING_FOR_EMPLOYERS_URL,
      "Employees",
    ).catch(() => {
      setLoading(false);
      setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
      setDisplayToast(true);
    });
    if (isExist) {
      setToastDetails({ toastState: "error", message: `${info} exists already` });
      setDisplayToast(true);
      setLoading(false);
      resetForm({
        values: {
          email: "",
        },
      });
      return;
    }
    const response = await fetch(OUTSOURCING_FOR_EMPLOYERS_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        json: values,
        sheet: "Employees",
      }),
    });
    return response
      .json()
      .then(() => {
        setLoading(false);
        resetForm();
        setToastDetails({ toastState: "success", message: "Upload Successful" });
        setDisplayToast(true);
      })
      .catch(() => {
        setLoading(false);
        setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
        setDisplayToast(true);
      });
  }

  return (
    <main className={styles.wrap}>
      {displayToast ? <Toast {...toastDetails} setDisplayToast={setDisplayToast} /> : ""}

      <div className={style.heroImage}>
        <h4>HIRE TALENT</h4>
        <h1>Secure Talented Professionals At A Competitive Rate</h1>
      </div>

      <form className={styles.mainWrap} onSubmit={handleSubmit}>
        <div className={styles.inputWrap1}>
          <OutsourcingInput
            label="First name"
            placeholder="Enter first name"
            onChange={handleChange}
            value={values.firstname}
            name="firstname"
            onBlur={handleBlur}
            errorText={errors.firstname && touched.firstname ? errors.firstname : ""}
          />
          <OutsourcingInput
            label="Last name"
            placeholder="Enter last name"
            onChange={handleChange}
            value={values.lastname}
            name="lastname"
            onBlur={handleBlur}
            errorText={errors.lastname && touched.lastname ? errors.lastname : ""}
          />
        </div>
        <div className={styles.inputWrap}>
          <OutsourcingInput
            label="Your work email address"
            placeholder="Enter email address"
            onChange={handleChange}
            value={values.email}
            name="email"
            onBlur={handleBlur}
            errorText={errors.email && touched.email ? errors.email : ""}
          />
          <OutsourcingInput
            label="Number of employees"
            placeholder="Enter number"
            onChange={handleChange}
            value={values.noOfEmployees}
            name="noOfEmployees"
            onBlur={handleBlur}
            errorText={errors.noOfEmployees && touched.noOfEmployees ? errors.noOfEmployees : ""}
          />
        </div>

        <div className={styles.inputWrap}>
          <InputSelect
            label="Role hiring for"
            placeholder="Select role"
            onChange={handleChange}
            value={values.role}
            name="role"
            onBlur={handleBlur}
            icon={<DropdownIcon />}
            options={roles}
            errorText={errors.role && touched.role ? errors.role : ""}
          />
          <InputSelect
            label="Level of commitment "
            placeholder="Select type"
            onChange={handleChange}
            value={values.levelOfCommitment}
            name="levelOfCommitment"
            icon={<DropdownIcon />}
            options={levelOfCommitment}
            onBlur={handleBlur}
            errorText={errors.levelOfCommitment && touched.levelOfCommitment ? errors.levelOfCommitment : ""}
          />
        </div>

        <div className={styles.inputWrap}>
          <OutsourcingInput
            type="date"
            label="Proposed start date"
            onChange={handleChange}
            value={values.startDate}
            name="startDate"
            onBlur={handleBlur}
            errorText={errors.startDate && touched.startDate ? errors.startDate : ""}
          />
          <InputSelect
            label="Skill requirement "
            placeholder="Select type"
            onChange={handleChange}
            value={values.skillRequirement}
            name="skillRequirement"
            icon={<DropdownIcon />}
            options={skillRequirement}
            onBlur={handleBlur}
            errorText={errors.skillRequirement && touched.skillRequirement ? errors.skillRequirement : ""}
          />
        </div>

        <div className={styles.inputWrap}>
          <OutsourcingInput
            label="Company’s name"
            placeholder="Enter Company’s name"
            onChange={handleChange}
            value={values.companyName}
            name="companyName"
            onBlur={handleBlur}
            errorText={errors.companyName && touched.companyName ? errors.companyName : ""}
          />
          <OutsourcingInput
            label="Client/Contact Phone number"
            placeholder="Enter Contact Phone number"
            onChange={handleChange}
            value={values.phoneNumber}
            name="phoneNumber"
            onBlur={handleBlur}
            errorText={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : ""}
          />
        </div>

        <div className={styles.fluidWrap}>
          <OutsourcingInput
            label="Company’s website"
            placeholder="https://"
            onChange={handleChange}
            value={values.companyWebsite}
            name="companyWebsite"
            onBlur={handleBlur}
            errorText={errors.companyWebsite && touched.companyWebsite ? errors.companyWebsite : ""}
          />
        </div>
        <div className={styles.submitWrap}>
          <p>
            {/* By submitting to this form, you agree to Talent Outsourcing <span>terms</span> and <span>conditions</span> */}
          </p>
          <div className={styles.buttonWrap}>
            <OutsourcingButton className={styles.submitButton} type="submit">
              {loading ? "SUMBMITING..." : "SUBMIT FORM"}
            </OutsourcingButton>
          </div>
        </div>
      </form>
    </main>
  );
};

export { OutsourcingForEmployers };
