import * as Yup from "yup";
const phoneNumberRegEx = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const SUPPORTED_FORMATS = ["application/pdf"];
const FILE_SIZE = 25 * 1024 * 1024;
const JoinOurCommunitySchema = Yup.object().shape({
  firstname: Yup.string().required("Fist Name field is required"),
  lastname: Yup.string().required("Last Name field is required"),
  email: Yup.string().email("Please enter a valid email").required("Email field is required"),
  phoneNumber: Yup.string()
    .matches(phoneNumberRegEx, "Phone number is not valid")
    .required(" Phone number is a required field"),
  stack: Yup.string().required("Stack field is required"),
  skillRequirement: Yup.string().required("Skill requirement field is required"),
  resume: Yup.mixed()
    .test("required", "You need to provide a file", (file) => {
      if (file) return true;
      return false;
    })
    .test("fileSize", "File too large", (value) => value && value.size <= FILE_SIZE)
    .test(
      "fileFormat",
      "Unsupported Format:- Upload a PDF file",
      (value) => value && SUPPORTED_FORMATS.includes(value.type),
    ),
});

const JoinOurCommunityInitialState = {
  firstname: "",
  lastname: "",
  email: "",
  phoneNumber: "",
  resume: undefined,
  skillRequirement: "",
  stack: "",
};

export { JoinOurCommunitySchema, JoinOurCommunityInitialState };
