const fileUpload = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "rlaxp3l4");
    const response = await fetch("https://api.cloudinary.com/v1_1/genesys/image/upload", {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default fileUpload;
