import {
  Award,
  Chain,
  Dollar,
  LearningPathIconFour,
  LearningPathIconOne,
  LearningPathIconThree,
  LearningPathIconTwo,
  Map,
  Pin,
  Thumb,
} from "./svg";

const learnableAboutData = [
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1660904756/learnable-website/aboutImageOne_h5e000.png",
    blur: "https://res.cloudinary.com/genesys/image/upload/v1660906285/learnable-website/aboutImageOne_ghjw2y.jpg",
    alt: "about-us-one",
  },
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1660904757/learnable-website/aboutImageTwo_vo1c0x.png",
    blur: "https://res.cloudinary.com/genesys/image/upload/v1660906285/learnable-website/aboutImageTwo_gno4cp.jpg",
    alt: "about-us-two",
  },
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1660904756/learnable-website/aboutImageThree_hlrw0i.png",
    blur: "https://res.cloudinary.com/genesys/image/upload/v1660906285/learnable-website/aboutImageThree_eqmln2.jpg",
    alt: "about-us-three",
  },
];

const learnablePathData = [
  {
    Icon: LearningPathIconOne,
    header: "Front-end Web Development",
    paragraph:
      "Introduction to programming, git and version control, and JavaScript. Learn JavaScript design patterns and how to develop web apps using React. ",
    image:
      "https://res.cloudinary.com/genesys/image/upload/v1660873261/learnable-website/learnableLearningPath1_hki1m3.png",
  },
  {
    Icon: LearningPathIconTwo,
    header: "Back-end Web Development ",
    paragraph:
      "Introduction to programming, git and version control, and JavaScript. Learn all about Object Oriented Programming and Regular Expressions, JavaScript design patterns and how to develop APIs using Node.",
    image:
      "https://res.cloudinary.com/genesys/image/upload/v1660873264/learnable-website/learnableLearningPath2_v0wc9q.png",
  },
  {
    Icon: LearningPathIconThree,
    header: "Product Design",
    paragraph:
      "Designing User Interface (UI), Crafting User Experience (UX), branding products, design thinking, building information architecture, copywriting for projects.",
    image:
      "https://res.cloudinary.com/genesys/image/upload/v1660873264/learnable-website/learnableLearningPath3_etlitc.png",
  },
  {
    Icon: LearningPathIconFour,
    header: "Web3",
    paragraph:
      "Get to be a part of the new wave of web3 by learning the basics of web development and how to develop DApps with Solidity.",
    image:
      "https://res.cloudinary.com/genesys/image/upload/v1660873263/learnable-website/learnableLearningPath4_sewfng.png",
  },
];

const learnableFAQData = [
  {
    Icon: Dollar,
    header: "Is Learnable a paid internship?",
    paragraph: "We only pay monthly stipends to those that complete their internship physically at the hub.",
  },
  {
    Icon: Pin,
    header: "Must I be in Enugu to participate?",
    paragraph:
      "No. We have a completely virtual arm of Learnable, where interns will participate for six months from whatever part of the world. Although selected interns that are residents or have accommodation in Enugu have the opportunity to spend the last three months at the hub.",
  },
  { Icon: Award, header: "Do I get a certificate at the end of the programme?", paragraph: "Yes, you do. " },
  {
    Icon: Thumb,
    header: "Is Learnable Free?",
    paragraph: "The Learnable program is free of charge, you only have to pay an application fee.",
  },
  {
    Icon: Chain,
    header: "What is a Github/Behance link?",
    paragraph: "Kindly Google it, thanks.",
  },
  {
    Icon: Map,
    header: "Is Learnable just for Nigerians?",
    paragraph: "No, Learnable is designed to accommodate international interns.",
  },
];

const testimonialGridData = {
  gridTwoDetails: {
    image:
      "https://res.cloudinary.com/genesys/image/upload/v1661228064/learnable-website/testimonialGrid2Image_eaajrw.png",
    alt: "Grid-item-two-image",
    blur: "https://res.cloudinary.com/genesys/image/upload/v1661228064/learnable-website/testimonialGrid2Image_fpytap.jpg",
  },
  gridThreeDetails: {
    image:
      "https://res.cloudinary.com/genesys/image/upload/v1661226748/learnable-website/testimonialGrid3Image_otz9zl.png",
    alt: "Grid-item-three-image",
    blur: "https://res.cloudinary.com/genesys/image/upload/v1661226747/learnable-website/testimonialGrid3Image_wg4sud.jpg",
  },
  gridFourDetails: {
    image:
      "https://res.cloudinary.com/genesys/image/upload/v1661228730/learnable-website/testimonialGrid4Image_rkz4bd.png",
    alt: "Grid-item-four-image",
    blur: "https://res.cloudinary.com/genesys/image/upload/v1661228729/learnable-website/testimonialGrid4Image_set6ni.jpg",
  },
};

const learnableTestimonalData = [
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1661958473/learnable-website/Gloria_Okeke_pmxjoh.jpg",
    name: `Gloria Okeke (Learnable '${16})`,
    role: "Software Engineer",
    company: "Microsoft",
    comment: `Learnable was the foundation of my career. It exposed me to the realities of being a software engineer, and equipped me with the right tools to succeed in the field.`,
  },
  {
    image:
      "https://res.cloudinary.com/genesys/image/upload/v1661958472/learnable-website/Ositadinma_Nwangwu_lk2wje.jpg",
    name: `Ositadinma Nwangwu (Learnable '${16})`,
    role: "Product Manager",
    company: "Localized",
    comment: `If you're going to start a career in tech, learnable is your best bet.`,
  },
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1661958467/learnable-website/Obinna_Okoro_xkjvzs.jpg",
    name: `Obinna Okoro  (Learnable '${16})`,
    role: "Mobile Developer",
    company: "Bitfinex",
    comment: `Learnable was an incredible experience that contributed greatly in my Software Development career! I enjoyed the people I worked with. It was a friendly and fun atmosphere.`,
  },
  {
    image:
      "https://res.cloudinary.com/genesys/image/upload/v1662025044/learnable-website/Nzeh_Davison_Chiadikaobi_ubbdg6.jpg",
    name: `Nzeh Davison  (Learnable '${16})`,
    role: "Senior Software Developer (Backend)",
    company: "Wicrypt",
    comment: `At Learnable, you stand to experience real life work environment while you learn, you get to interact with senior developers and learn directly from their challenges. Learnable gives you personal mentors who are to ensure you come out with all qualities of a developer.`,
  },
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1661958468/learnable-website/Ogbonna_Vitalis_dtijfd.jpg",
    name: `Ogbonna Vitalis (Learnable '${17})`,
    role: "Senior Software Engineer",
    company: "Andela",
    comment: `Learnable is quite amazing, a citadel where we are formed.`,
  },
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1661958475/learnable-website/Jerry_Uke_jv2auu.jpg",
    name: `Jerry Uke (Learnable '${17})`,
    role: "Program Manager",
    company: "AltSchool Africa",
    comment: `Learnable is simply the best foundation for anyone who wants a very successful career in tech. I'd never forget my experience.`,
  },
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1662025044/learnable-website/Richard_Nnaji_dz2dyx.jpg",
    name: `Richard Nnaji (Learnable '${17})`,
    role: "Senior Software Engineer",
    company: "Pioneer Corporation",
    comment: `My Learnable experience was terrific: a well-structured curriculum with experienced instructors helped introduce and build me properly in my software career, balancing my hard and soft skills.`,
  },
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1662025046/learnable-website/Chidera_Olibie_qwlprw.jpg",
    name: `Chidera Olibie (Learnable '${18})`,
    role: "Software Engineer",
    company: "Google",
    comment: `I think, what I really liked about Learnable was that it showed me what was possible. I met people doing amazing things which showed me the gaps in my knowledge and what I needed to do. It gave me a better glimpse outside of tutorials into what the developer life was about. I learnt more about teamwork and grew my skills immensely. It also gave me the confidence to call myself a software engineer.`,
  },
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1661958469/learnable-website/Obinna_Ekwuno_zudnow.jpg",
    name: `Obinna Ekwuno (Learnable '${18})`,
    role: "Developer Advocate",
    company: "Cloudflare",
    comment: `Learnable is an incredible experience that introduces you to a lot of the major skills you'll need when working on large projects from  understanding Product requirements to figuring out market fit and then building the actual application.`,
  },
  {
    image:
      "https://res.cloudinary.com/genesys/image/upload/v1661958466/learnable-website/Chinonso_Williams_Junior_zr6vq9.jpg",
    name: `Chinonso Williams (Learnable '${18})`,
    role: "CTO",
    company: "UrbanID Global",
    comment: `Everything is learnable.`,
  },
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1661958473/learnable-website/Luke_Mmamel_qgv0al.jpg",
    name: `Luke Mmamel (Learnable '${18})`,
    role: "Lead Product Designer",
    company: "Obiex",
    comment: `Learnable saved my life and helped me find my path. `,
  },
  {
    image:
      "https://res.cloudinary.com/genesys/image/upload/v1661958477/learnable-website/Ohalewe_Chiemezie_Richmond_oareou.jpg",
    name: `Ohalewe Richmond (Learnable '${19})`,
    role: "Lead Designer",
    company: "Yebox Technologies",
    comment: `Learnable was a pathway for me to find my true self as a designer and encouraged me to harness all my potential in becoming who I am today.`,
  },
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1661958473/learnable-website/Charles_Ekube_vqpbgq.jpg",
    name: `Charles Ekube (Learnable '${20})`,
    role: "Software Engineer (Mobile)",
    company: "Obiex Finance",
    comment: `Learnable is an amazing platform that gives you all the energy and vibes you need to take on the world of tech.`,
  },
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1661958464/learnable-website/Chinedum_Echendu_cq1cyg.jpg",
    name: `Chinedum Echendu (Learnable '${20})`,
    role: "Backend Engineer",
    company: "Common Identity",
    comment: `You will become what you thought was impossible.`,
  },
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1661958465/learnable-website/Yvonne_Ogwulu_omanjw.jpg",
    name: `Yvonne Ogwulu  (Learnable '${20})`,
    role: "Product Designer",
    company: "Bitmama",
    comment: `Learnable is really amazing. It's so amazing that you probably won't find the right words to describe the experience until you get to experience it for yourself. For me, those were some of the best months I've ever experienced - getting to learn and do what I'm passionate about in an environment that is not your regular office atmosphere, and then meeting many amazing people with like-minds and passion who become life-long friends. It's an experience I'll forever be grateful for! `,
  },
  {
    image: "https://res.cloudinary.com/genesys/image/upload/v1661958472/learnable-website/Elliot_Yibalua_jcur7i.jpg",
    name: `Elliot Yibaebi  (Learnable '${20})`,
    role: "Software Engineer",
    company: "Appknox",
    comment: `I learnt a lot about my self and grew technically, intellectually, and socially. At the end, I found love from within. Something I am really grateful for. I would not forget to mention the amazing culture at Genesys. A culture that still thrives on post-internship and has become even stronger with time. Thank you Learnable, for positively changing the trajectory of my life.`,
  },
  {
    image:
      "https://res.cloudinary.com/genesys/image/upload/v1661958474/learnable-website/Chidiebere_Ekennia_jpkmji.jpg",
    name: `Chidiebere Ekennia  (Learnable '${20})`,
    role: "Assistant C.T.O",
    company: "Blockchainhub Africa",
    comment: `It's still the best learning experience I've ever had.`,
  },
];

export { learnableTestimonalData, testimonialGridData, learnableFAQData, learnablePathData, learnableAboutData };
