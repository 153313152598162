import React from "react";
import styles from "./inputSelect.module.css";

const InputSelect = ({
  placeholder,
  value,
  className,
  label,
  onChange,
  disabled,
  name,
  icon,
  errorText,
  options = [],
}) => {
  return (
    <div className={`${className} ${styles.selectWrapper}`}>
      <label className={`${className} ${styles.selectLabel}`}> {label}</label>
      <select
        className={`className ${styles.selectElement}`}
        name={name}
        onChange={onChange}
        disabled={disabled}
        defaultValue={value}
      >
        <option disabled value="">
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} className={`className ${styles.selectElement}`} value={option?.value ?? option}>
            {option?.name ?? option}
          </option>
        ))}
      </select>
      <span>{icon}</span>
      <p className={styles.errorTextElement}>{errorText}</p>
    </div>
  );
};

export { InputSelect };
