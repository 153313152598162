import React from "react";

import styles from "./styles.module.css";

const InputField = ({ className, type, placeholder, id, onChange, ...rest }) => {
  return (
    <div className={styles.inputTextWrapper}>
      <input
        className={`${styles.inputText} ${className}`}
        type={type}
        placeholder={placeholder}
        id={id}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};
export { InputField };
