/* eslint-disable react/jsx-key */
import React from "react";
import { OutsourcingAbout, OutsourcingContact, OutsourcingForEmployers, JoinOurCommunity } from "../../pages";

const outsourcingRoute = [
  {
    path: "/",
    element: <OutsourcingAbout />,
  },
  {
    path: "/employers",
    element: <OutsourcingForEmployers />,
  },
  {
    path: "/contact",
    element: <OutsourcingContact />,
  },
  {
    path: "/join",
    element: <JoinOurCommunity />,
  },
];

export { outsourcingRoute };
