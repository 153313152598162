/* eslint-disable no-undef */
import React, { useState, useRef } from "react";
import { OutsourcingInput, InputSelect, OutsourcingButton, Toast } from "../../../components";
import { DropdownIcon, UploadIcon } from "../../../assets/svg";
import { areaOfExpertise } from "../../../utils/constants";
import styles from "./styles.module.css";
import style from "../styles.module.css";
import { useFormik } from "formik";
import { JoinOurCommunityInitialState, JoinOurCommunitySchema } from "./validation.js";
import { OUTSOURCING_FOR_COMMUNITY_URL } from "../../../config";
import fileUpload from "./file_upload";
import exists from "./querysearch";

const JoinOurCommunity = () => {
  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const [displayToast, setDisplayToast] = useState(false);
  const [toastDetails, setToastDetails] = useState({ toastState: "", message: "" });

  const handleImageClick = (e) => {
    e.preventDefault();
    ref.current?.click();
  };
  const formik = useFormik({
    initialValues: JoinOurCommunityInitialState,
    validationSchema: JoinOurCommunitySchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const { info, isExist } = await exists(
        values.email,
        values.phoneNumber,
        OUTSOURCING_FOR_COMMUNITY_URL,
        "Community",
      ).catch(() => {
        setLoading(false);
        setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
        setDisplayToast(true);
      });
      if (isExist) {
        setToastDetails({ toastState: "error", message: `${info} exists already` });
        resetForm({
          values: {
            email: JoinOurCommunityInitialState.email,
          },
        });
        setDisplayToast(true);
        setLoading(false);
        return;
      }
      fileUpload(values.resume)
        .then((res) => {
          if (res.error) {
            setLoading(false);
            setToastDetails({ toastState: "error", message: res.error.message });
            setDisplayToast(true);
            return;
          }
          fetch(OUTSOURCING_FOR_COMMUNITY_URL, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              json: {
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email,
                phoneNumber: values.phoneNumber,
                resume: res.secure_url,
                skillRequirement: values.skillRequirement,
                stack: values.stack,
              },
              sheet: "Community",
            }),
          })
            .then((response) => response.json())
            .then(() => {
              setLoading(false);
              resetForm();
              setToastDetails({ toastState: "success", message: "Data Stored Successfully" });
              setDisplayToast(true);
            });
        })
        .catch(() => {
          setLoading(false);
          setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
          setDisplayToast(true);
        });
    },
  });
  const { handleSubmit, handleChange, errors, values, setFieldValue, handleBlur, touched } = formik;

  return (
    <main className={styles.wrap}>
      {displayToast ? <Toast {...toastDetails} setDisplayToast={setDisplayToast} /> : ""}

      <div className={style.heroImage}>
        <h4>JOIN OUR COMMUNITY</h4>
        <h1>Join our amazing community and find yourself at the center of opportunities</h1>
      </div>

      <form className={styles.mainWrap} onSubmit={handleSubmit}>
        <div className={styles.inputWrap1}>
          <OutsourcingInput
            label="First name"
            placeholder="Enter first name"
            name="firstname"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstname}
            errorText={touched.firstname && errors.firstname ? errors.firstname : ""}
          />
          <OutsourcingInput
            label="Last name"
            placeholder="Enter last name"
            name="lastname"
            onChange={handleChange}
            value={values.lastname}
            onBlur={handleBlur}
            errorText={touched.lastname && errors.lastname ? errors.lastname : ""}
          />
        </div>
        <div className={styles.inputWrap}>
          <OutsourcingInput
            label="Your email address"
            placeholder="Enter email address"
            name="email"
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            errorText={touched.email && errors.email ? errors.email : ""}
          />
          <OutsourcingInput
            label="Phone number"
            placeholder="Enter Contact Phone number"
            name="phoneNumber"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phoneNumber}
            errorText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ""}
          />{" "}
        </div>

        <div className={styles.inputWrap}>
          <InputSelect
            label="Area of expertise "
            placeholder="Select type"
            name="skillRequirement"
            icon={<DropdownIcon />}
            onChange={handleChange}
            value={values.skillRequirement}
            options={areaOfExpertise}
            onBlur={handleBlur}
            errorText={touched.skillRequirement && errors.skillRequirement ? errors.skillRequirement : ""}
          />
          <OutsourcingInput
            label="Language/Technology/Stack"
            placeholder="Enter stack"
            name="stack"
            onChange={handleChange}
            value={values.stack}
            onBlur={handleBlur}
            errorText={touched.stack && errors.stack ? errors.stack : ""}
          />
        </div>

        <div className={styles.uploadWrap}>
          <label className={styles.resumeLabel}>Resume</label>
          <input
            ref={ref}
            id="file"
            className={styles.inputFile}
            label="Resume"
            name="resume"
            type="file"
            onChange={(event) => setFieldValue("resume", event.currentTarget.files[0])}
            onBlur={handleBlur}
          />
          <div className={styles.inputFileWrap} onClick={handleImageClick}>
            <UploadIcon />
            <label>
              Drag and drop your file(s) or <span>browse to upload </span>
              <p className={styles.resumePlaced}>{values.resume ? values.resume.name : ""}</p>
              <p className={styles.resumeError}>{touched.resume && errors.resume ? errors.resume : ""}</p>
            </label>
          </div>
        </div>

        <div className={styles.buttonWrap}>
          <OutsourcingButton type={"submit"} className={styles.submitButton}>
            {loading ? "SENDING..." : "SEND"}
          </OutsourcingButton>
        </div>
      </form>
    </main>
  );
};

export { JoinOurCommunity };
