import { object, string, date } from "yup";

const phoneNumberRegEx = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const numberOnly = (value) => /^\d+$/.test(value);

export const forEmployersSchema = object({
  firstname: string().required(),
  lastname: string().required(),
  email: string().email().required(),
  noOfEmployees: string()
    .required("No of employees is a required field")
    .test("Numbers only", "Numbers only", numberOnly),
  role: string().required(),
  levelOfCommitment: string().required("Level of commitment is a required field"),
  startDate: date().required(),
  skillRequirement: string().required("Skill requirement is a required field"),
  companyName: string().required("Company name is arequired field"),
  phoneNumber: string()
    .matches(phoneNumberRegEx, "Phone number is not valid")
    .required(" Phone number is a required field"),
  companyWebsite: string().url().required(" Company website is a required field"),
});
