import React from "react";
import styles from "./styles.module.css";

const OutsourcingButton = ({ children, filledType, buttonStyle, className, type, ...rest }) => {
  return (
    <button
      className={`${className || ""} ${styles.wrap} ${filledType ? styles["button-filled"] : ""} ${buttonStyle || ""}`}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
};

export { OutsourcingButton };
