import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, InputField } from "../../";
import { SHEET_DB_URL } from "../../../config";
import { OutsourcingButton } from "../../../components/outsourcingButton";
import { Toast } from "../../toast";

import styles from "./styles.module.css";
import { emailExists } from "../../../pages/outsourcing/outsourcingCommunity/querysearch";

const LearnableFooter = ({ outsourcingButton }) => {
  const [email, setEmail] = useState("");
  const [displayToast, setDisplayToast] = useState(false);
  const [load, setLoad] = useState(false);
  const [toastDetails, setToastDetails] = useState({ toastState: "", message: "" });

  const subscribeToMailList = async (e) => {
    e.preventDefault();
    setLoad(true);
    const info = await emailExists(email, SHEET_DB_URL).catch(() => {
      setLoad(false);
      setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
      setDisplayToast(true);
    });
    if (info) {
      setToastDetails({ toastState: "error", message: `${info} exists already` });
      setDisplayToast(true);
      setLoad(false);
      return;
    }
    fetch(SHEET_DB_URL, {
      method: "POST",
      body: JSON.stringify({ json: { email }, sheet: "Mailing List" }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response?.status >= 400) {
          setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
          setDisplayToast(true);
        } else {
          setEmail("");
          setToastDetails({ toastState: "success", message: "You have joined our mailing list!" });
          setDisplayToast(true);
        }
      })
      .catch(() => {
        setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
        setDisplayToast(true);
      })
      .finally(() => setLoad(false));
  };

  return (
    <div className={styles.container}>
      {displayToast ? <Toast {...toastDetails} setDisplayToast={setDisplayToast} /> : ""}
      <footer>
        <section className="learnable-container">
          <div className={styles.routes}>
            <div>
              <h2>USEFUL LINKS</h2>
              <ul>
                <li>
                  <Link to="/">ABOUT</Link>
                </li>
                <li>
                  <Link to="/employers">FOR EMPLOYERS</Link>
                </li>
                <li>
                  <Link end to="/contact">
                    CONTACT
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.formContainer}>
            <h1>Stay Connected</h1>
            <p>Join our mailing list to receive information about our work and new learning opportunities.</p>
            <div className={styles["form"]}>
              <form onSubmit={subscribeToMailList}>
                <InputField
                  placeholder="Enter Your Email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={({ target: { value } }) => setEmail(value)}
                  required
                />

                {outsourcingButton ? (
                  <OutsourcingButton className={styles._footerButton}>
                    {load ? "Loading..." : "SUBSCRIBE"}
                  </OutsourcingButton>
                ) : (
                  <Button filledType={true} disabled={load}>
                    {load ? "Loading..." : "SUBSCRIBE"}
                  </Button>
                )}
              </form>
            </div>
          </div>
        </section>
        <section className={outsourcingButton ? styles["outsourcingBackground"] : ""}>
          <div className="learnable-container">
            <div className={styles.copyright}>
              <span>&copy; HUBLY 2023</span>
            </div>
          </div>
        </section>
      </footer>
    </div>
  );
};

export { LearnableFooter };
