import React from "react";
import styles from "./styles.module.css";

const Textarea = ({ value, onChange, rows, name, required, errorText, textareaText, textareaLabel, ...restProps }) => {
  return (
    <div className={styles.textareaWrapper}>
      <label className={styles.textareaLabelElement}>{textareaLabel}</label>
      <textarea
        className={styles.textareaElement}
        rows={rows}
        onChange={onChange}
        value={value}
        name={name}
        required={required}
        {...restProps}
      >
        {textareaText}
      </textarea>
      <span className={styles.errorTextElement}>{errorText}</span>
    </div>
  );
};

export { Textarea };
