import React, { useState } from "react";
import { Link } from "react-router-dom";
import CarouselSlider from "react-carousel-slider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card } from "./components/card";
import {
  infoImage,
  boy1,
  boy2,
  girl1,
  girl2,
  smilingGirl,
  chineseBoy,
  blackBoy,
  wynMobile1,
  wynMobile2,
  wynMobile3,
  wyn2,
  wyn3,
  activePng,
  avater,
  teneceLogo,
  wicryptLogo,
  interswitch,
  xend,
  taj,
  stanbic,
  smile,
} from "../../../assets/images";
import { TransperentRocket, ActiveStep, Quotes, LeftArrow, RightArrow } from "../../../assets";
import { OutsourcingButton } from "../../../components/outsourcingButton";
import styles from "./styles.module.css";
import {
  blueText,
  manner,
  jsonDataSliderOne,
  sliderOnecontainer,
  cusImg,
  buttonSetting,
  title,
  grey,
  sliderBoxStyle,
  responsive,
  cardtitle,
  cardRole,
  jsonDataMobileSlider,
  personalData,
  nameWrap,
  mobileSliderBoxStyle,
  multiWrap,
  mainContentWrap,
  jsonDataSliderTwo,
} from "./style";

const OutsourcingAbout = () => {
  const [id, setId] = useState(1);

  const [height, setHeight] = useState();
  window.addEventListener("scroll", () => {
    setHeight(window.scrollY);
  });

  const items = jsonDataSliderOne.map((item, index) => (
    <div style={sliderOnecontainer} key={index}>
      <img style={cusImg} src={item.imgSrc} />
      <h5 style={title}>{item.name}</h5>
      <div>
        <h4 style={grey}>{item.role}</h4>
        <h4 style={blueText}>{item.workPlace}</h4>
      </div>
    </div>
  ));

  const itemThree = jsonDataMobileSlider.map((item, index) => {
    return (
      <div key={index}>
        <img className={styles.logo} src={item.icon} alt="some" />
      </div>
    );
  });

  return (
    <main className={styles._wrap}>
      <section className={styles.firstSection}>
        <div>
          <h2 className={styles.bannerHeader}>Connecting Brilliant Minds And Companies To Build The Future</h2>
          <p className={styles.fifthP}>
            We connect African talents with businesses in tier 1 and 2 countries, providing accessible and vetted talent
            for companies in these countries.
          </p>
          <Link to="/join" className={styles.firstSectionButtonWrap}>
            <OutsourcingButton>
              JOIN OUR COMMUNITY <TransperentRocket />{" "}
            </OutsourcingButton>
          </Link>
        </div>

        <div className={styles.gridContainer}>
          <div className={styles.span2Wrap}>
            <div className={styles["gridItem"]}>
              <img src={smilingGirl} alt="girl" />
            </div>

            <div className={styles["gridItem"]}>
              <img src={chineseBoy} alt="boy" />
            </div>
          </div>

          <div className={styles.blackboyWrap}>
            <img src={blackBoy} alt="boy" />
          </div>
        </div>
      </section>

      <section className={styles.secondSection}>
        <img src={teneceLogo} alt="tenece logo" />
        <img src={interswitch} alt="interswitch logo" />
        <img src={smile} alt="smile logo" />
        <img src={stanbic} alt="hubly logo" />
        <img src={taj} alt="taj logo" />
        <img src={xend} alt="xend logo" />
        <img src={wicryptLogo} alt="logo" />
      </section>
      <section className={styles.mobileSecondSection}>
        <CarouselSlider
          slideCpnts={itemThree}
          manner={manner}
          sliderBoxStyle={mobileSliderBoxStyle}
          buttonSetting={buttonSetting}
          accEle={{ dots: false }}
        />
      </section>

      <div>
        <h5 className={styles.sectionThreeTitle}>We have what your company needs</h5>
      </div>

      <section className={styles.thirdSection}>
        <div className={styles.stepwrapper}>
          <div className={styles.stepwrapperBorder}></div>
          <ActiveStep onClick={() => setId(1)} fill={id == 1 && height <= "1060" ? "black" : "white"} />
          <ActiveStep
            onClick={() => setId(2)}
            fill={id == 2 || (height > "1061" && height <= "1150") ? "black" : "white"}
          />
          <ActiveStep onClick={() => setId(3)} fill={id == 3 || height > "1151" ? "black" : "white"} />
        </div>
        <div className={styles.mainContent}>
          <div>
            <p
              className={
                id === 1 && id < 3 && height <= "1060" ? styles["activeTextStyle"] : styles["inactiveTextStyle"]
              }
            >
              Our vision is to be the preferred talent outsourcing firm for clients all over the United States and other
              tier 1 countries. We are committed to demonstrating fair business ethics at every point of our business
              and holding ourselves accountable for our high standards.
            </p>
            <p
              className={
                (id === 2 && id < 3) || (height > "1061" && height <= "1150")
                  ? styles["activeTextStyle"]
                  : styles["inactiveTextStyle"]
              }
            >
              We partner with EORs and PEOs to ensure that businesses can hire contractors and FTEs without worrying
              about complicated local labor laws, allowing them to focus on their core business.
            </p>
            <p className={id === 3 || height > "1151" ? styles["activeTextStyle"] : styles["inactiveTextStyle"]}>
              Our team partners with the Genesys Tech Hub Foundation, a leading talent training and development company
              in Nigeria, to ensure the availability of a diverse pool of talented professionals at competitive rates.
              We started with local companies in the Enugu metropolis and we’ve grown to service business nationally and
              internationally.
            </p>
          </div>
          <div>
            {id === 1 && height <= "1060" ? (
              <img src={infoImage} alt="info image" />
            ) : id === 2 || (height > "1061" && height <= "1150") ? (
              <img src={wyn2} alt="info image" />
            ) : id === 3 || height > "1151" ? (
              <img src={wyn3} alt="info image" />
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      {/* mobile scroll  */}
      <section className={styles.mobileThirdSection}>
        <div className={styles.mobileStepwrapperBorder}></div>

        <div className={styles.mobileWynWrap}>
          <div className={styles.mobileText}>
            <img src={activePng} alt="active icon" />
            <p>
              Our vision is to be the preferred talent outsourcing firm for clients all over the United States and other
              tier 1 countries. We are committed to demonstrating fair business ethics at every point of our business
              and holding ourselves accountable for our high standards.
            </p>
          </div>

          <div className={styles.mobileImageWrap}>
            <img src={wynMobile1} alt="Info Image" />
          </div>
        </div>

        <div className={styles.mobileWynWrap}>
          <div className={styles.mobileText}>
            <img src={activePng} alt="active icon" />
            <p>
              We partner with EORs and PEOs to ensure that businesses can hire contractors and FTEs without worrying
              about complicated local labor laws, allowing them to focus on their core business.
            </p>
          </div>

          <div className={styles.mobileImageWrap}>
            <img src={wynMobile2} alt="Info Image" />
          </div>
        </div>

        <div className={styles.mobileWynWrap}>
          <div className={styles.mobileText}>
            <img src={activePng} alt="active icon" />
            <p>
              Our team partners with thee Genesys Tech Hub Foundation, a leading talent training and development company
              in Nigeria, to ensure the availability of a diverse pool of talented professionals at competitive rates.
              We started with local companies in the Enugu metropolis and we’ve grown to service business nationally and
              internationally.
            </p>
          </div>

          <div className={styles.mobileImageWrap}>
            <img src={wynMobile3} alt="Info Image" />
          </div>
        </div>
      </section>

      <section className={styles.fourthSection}>
        <div>
          <div className={styles.ImgContainer}>
            <img src={boy1} alt="boy one" />
          </div>
          <div className={styles.ImgContainer}>
            <img src={boy2} alt="boy two" />
          </div>
        </div>
        <div className={styles.textWrap}>
          <h6>OUR TALENT POOL</h6>
          <p>We have vetted an amazing number of talents</p>
          <h5>2000+ Talents</h5>
        </div>
        <div>
          <div className={styles.ImgContainer}>
            <img src={girl1} alt="girl one" />
          </div>
          <div className={styles.ImgContainer}>
            <img src={girl2} alt="girl two" />
          </div>
        </div>
      </section>

      <section className={styles.fifthSection}>
        <div className={styles.fifthSectionText}>
          <h2>Featured Talents</h2>
          <p className={styles.fifthP}>
            We can only say soomuch with the testimonies we have from our featured talents and their rise to the
            top,working to top companies around the world.
          </p>

          <Link to="/employers" className={styles.fifthButtonWrap}>
            <OutsourcingButton>HIRE TALENT</OutsourcingButton>
          </Link>
        </div>

        <div className={styles.fifthSectionImgCard}>
          <CarouselSlider
            slideCpnts={items}
            sliderBoxStyle={sliderBoxStyle}
            manner={manner}
            buttonSetting={buttonSetting}
            accEle={{ button: false }}
          />
        </div>
        <div className={styles.mobileFifthSectionImgCard}>
          {jsonDataSliderOne.map(({ imgSrc, role, name, workPlace }, index) => (
            <Card key={index} src={imgSrc} title={name} description={role} work={workPlace} />
          ))}
        </div>
      </section>
      <section className={styles.sixSection}>
        <div className={styles.testimonialHeader}>
          <h5>You can read what our clients think</h5>
        </div>

        <div style={multiWrap}>
          <Carousel
            additionalTransfrom={0}
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            customLeftArrow={
              <div className={styles.leftSvg}>
                <LeftArrow fill="black" />
              </div>
            }
            customRightArrow={
              <div className={styles.rightSvg}>
                <RightArrow fill="black" />
              </div>
            }
            responsive={responsive}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {jsonDataSliderTwo.map(({ des, name, role }, index) => (
              <div className={styles.cardWrap} key={index}>
                <div className={styles.fakeBorderWrap}>
                  <div className={styles.fakeBorder}></div>
                </div>
                <div style={mainContentWrap}>
                  <Quotes />
                  <div className={styles.pWrap}>
                    <h6 className={styles.cardText}>{des}</h6>
                  </div>
                  <div style={personalData}>
                    <img className={styles.cardImgMobile} src={avater} alt="avater" />
                    <div style={nameWrap}>
                      <h5 style={cardtitle}>{name}</h5>
                      <h6 style={cardRole}>{role}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </section>

      <section className={styles.lastSection}>
        <div>
          <h3 className={styles.lsTitle}>Get the latest from our community.</h3>
          <p className={styles.lsText}>
            Sign up to be the first to receive updates on our available talents ready for hiring. Plus, learn how to
            secure one immediately while they are still very acquirable{" "}
          </p>
        </div>

        <div>
          <Link to="/join">
            <OutsourcingButton>join our community </OutsourcingButton>
          </Link>
          <p className={styles.disclaimer}>
            {/* By using this website you agree to our <span>Terms of Use</span> and <span>Privacy Policy</span> */}
          </p>
        </div>
      </section>
    </main>
  );
};

export { OutsourcingAbout };
