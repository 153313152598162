import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { OutsourcingLayout } from "../layouts";
import { outsourcingRoute } from "./routes";
import { ScrollToTop } from "./utils";

const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {outsourcingRoute.map((routeProps, idx) => (
          <Route
            key={`${routeProps.path} ${idx}`}
            caseSensitive={true}
            {...routeProps}
            element={<OutsourcingLayout>{routeProps.element}</OutsourcingLayout>}
          />
        ))}
      </Routes>
    </Router>
  );
};

export default AppRouter;
