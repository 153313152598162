import React from "react";
import styles from "./styles.module.css";

const OutsourcingInput = ({ label, icon, error, errorText, ...restProps }) => {
  return (
    <div className={styles.inputWrapper}>
      <label className={styles.inputLabel}> {label}</label>
      {icon === "" ? (
        <div>
          <input className={`className ${error ? styles.errorBorder : styles.inputElement} }`} {...restProps} />
          <p className={styles.errorTextElement}>{errorText}</p>
        </div>
      ) : (
        <div className={styles.withIcon}>
          <input className={`className ${error ? styles.errorBorder : styles.inputElement} }`} {...restProps} />
          <span className={styles.iconElement}>{icon}</span>
          <p className={styles.errorTextElement}>{errorText}</p>
        </div>
      )}
    </div>
  );
};

export { OutsourcingInput };
