import React from "react";

import styles from "./styles.module.css";

const Button = ({ children, filledType, buttonStyle, className, ...rest }) => {
  return (
    <button className={`${className || ""} ${styles.container} ${filledType ? styles["button-filled"] : ""} ${buttonStyle || ""}`} {...rest}>
      {children}
    </button>
  );
};

export { Button };
