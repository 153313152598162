import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HublyLogo, RightArrow, Cancel, Hamburger } from "../../assets";
import styles from "./styles.module.css";

const OutsourcingHeader = () => {
  const [mobileNav, setMobileNav] = useState(false);

  return (
    <>
      <header className={styles._wrap}>
        <div>
          <NavLink end to="/">
            <HublyLogo />
          </NavLink>
        </div>

        <nav>
          <ul className={styles._ul}>
            <li>
              <NavLink end to="/">
                ABOUT
              </NavLink>
            </li>
            <li>
              <NavLink end to="/employers">
                FOR EMPLOYERS
              </NavLink>
            </li>
            <li>
              <NavLink end to="/contact">
                CONTACT
              </NavLink>
            </li>
          </ul>
        </nav>

        <div>
          <button className={styles._button}>
            <NavLink to="/employers">
              <span>HIRE TALENT</span>
              <RightArrow className={styles.arrow} fill="#000" />
            </NavLink>
          </button>
        </div>
      </header>

      {/* mobile header  */}
      <header className={styles.mobileHeader}>
        <div className={styles.mobileLogo}>
          <div>
            <NavLink end to="/">
              <HublyLogo />
            </NavLink>
          </div>

          {mobileNav ? (
            <div onClick={() => setMobileNav(!mobileNav)}>
              <Cancel />
            </div>
          ) : (
            <div onClick={() => setMobileNav(!mobileNav)}>
              <Hamburger />
            </div>
          )}
        </div>

        {mobileNav ? (
          <nav>
            <ul className={styles._mobileUl}>
              <li onClick={() => setMobileNav(!mobileNav)}>
                <NavLink end to="/">
                  ABOUT
                </NavLink>
              </li>
              <li onClick={() => setMobileNav(!mobileNav)}>
                <NavLink end to="/employers">
                  FOR EMPLOYERS
                </NavLink>
              </li>
              <li onClick={() => setMobileNav(!mobileNav)}>
                <NavLink end to="/contact">
                  CONTACT
                </NavLink>
              </li>
            </ul>
          </nav>
        ) : (
          <></>
        )}
      </header>
    </>
  );
};

export { OutsourcingHeader };
