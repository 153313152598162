import * as Yup from "yup";
const phoneNumberRegEx = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const contactSchema = Yup.object().shape({
  firstname: Yup.string().required("Fist Name field is required"),
  lastname: Yup.string().required("Last Name field is required"),
  email: Yup.string().email("Please enter a valid email").required("Email field is required"),
  phoneNumber: Yup.string()
    .matches(phoneNumberRegEx, "Phone number is not valid")
    .required(" Phone number is a required field"),
  message: Yup.string().required("Message field is required"),
});

const contactInitialState = {
  firstname: "",
  lastname: "",
  email: "",
  phoneNumber: "",
  message: "",
};

export { contactSchema, contactInitialState };
