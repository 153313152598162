import React from "react";
import { LearnableFooter, OutsourcingHeader } from "../../components";
import styles from "./styles.module.css";

const OutsourcingLayout = ({ children }) => {
  return (
    <div>
      <OutsourcingHeader />
      <div className={styles.children}>{children}</div>
      <LearnableFooter outsourcingButton />
    </div>
  );
};

export { OutsourcingLayout };
