/* eslint-disable no-undef */
const MEDIUM_URL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/genesys-tech-hub";

//  Codeville Application Links
const CODEVILLE_APP_LINKS = {
  generalLink: "",
  junior: "https://forms.gle/h8iqazHYhGpKD4og6",
  middleClass: "",
  juniorAcademy: "",
  juniorAcademyVirtual: "",
};

// Google Sheets API Details
const SHEET_DB_URL = process.env.REACT_APP_SHEET_DB_URL;
const OUTSOURCING_FOR_EMPLOYERS_URL = process.env.REACT_APP_OUTSOURCING_FOR_EMPLOYERS_URL;
const OUTSOURCING_FOR_COMMUNITY_URL = process.env.REACT_APP_OUTSOURCING_FOR_COMMUNITY_URL;
const OUTSOURCING_FOR_CONTACT_URL = process.env.REACT_APP_OUTSOURCING_FOR_CONTACT_URL;

export {
  MEDIUM_URL,
  CODEVILLE_APP_LINKS,
  SHEET_DB_URL,
  OUTSOURCING_FOR_EMPLOYERS_URL,
  OUTSOURCING_FOR_COMMUNITY_URL,
  OUTSOURCING_FOR_CONTACT_URL,
};
