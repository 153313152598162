import React, { useState } from "react";
import { OutsourcingInput, OutsourcingButton, Textarea, Toast } from "../../../components";
import style from "../styles.module.css";
import styles from "./styles.module.css";
import { contactInitialState, contactSchema } from "./validation";
import { useFormik } from "formik";
import { OUTSOURCING_FOR_CONTACT_URL } from "../../../config";
import exists from "../outsourcingCommunity/querysearch";

const OutsourcingContact = () => {
  const [loading, setLoading] = useState(false);
  const [displayToast, setDisplayToast] = useState(false);
  const [toastDetails, setToastDetails] = useState({ toastState: "", message: "" });
  const formik = useFormik({
    initialValues: contactInitialState,
    validationSchema: contactSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const { info, isExist } = await exists(
        values.email,
        values.phoneNumber,
        OUTSOURCING_FOR_CONTACT_URL,
        "Contact",
      ).catch(() => {
        setLoading(false);
        setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
        setDisplayToast(true);
      });
      if (isExist) {
        setToastDetails({ toastState: "error", message: `${info} exists already` });
        setDisplayToast(true);
        setLoading(false);
        resetForm({
          values: {
            email: "",
          },
        });
        return;
      }
      fetch(OUTSOURCING_FOR_CONTACT_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          json: values,
          sheet: "Contact",
        }),
      })
        .then((response) => response.json())
        .then(() => {
          setLoading(false);
          resetForm();
          setToastDetails({ toastState: "success", message: "Data Stored Successfull" });
          setDisplayToast(true);
        })
        .catch(() => {
          setLoading(false);
          setToastDetails({ toastState: "error", message: "Something went wrong!!!" });
          setDisplayToast(true);
        });
    },
  });
  const { handleSubmit, handleChange, errors, values, handleBlur, touched } = formik;
  return (
    <main className={styles.wrap}>
      {displayToast ? <Toast {...toastDetails} setDisplayToast={setDisplayToast} /> : ""}

      <div className={style.heroImage}>
        <h4>CONTACT US</h4>
        <h1>Tell us what you need. we are here to help</h1>
      </div>

      <form className={styles.mainWrap} onSubmit={handleSubmit}>
        <div className={styles.inputWrap1}>
          <OutsourcingInput
            label="First name"
            placeholder="Enter first name"
            name="firstname"
            onChange={handleChange}
            value={values.firstname}
            onBlur={handleBlur}
            errorText={touched.firstname && errors.firstname ? errors.firstname : ""}
          />
          <OutsourcingInput
            label="Last name"
            placeholder="Enter last name"
            name="lastname"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastname}
            errorText={touched.lastname && errors.lastname ? errors.lastname : ""}
          />
        </div>
        <div className={styles.inputWrap}>
          <OutsourcingInput
            label="Phone number"
            placeholder="Enter Contact Phone number"
            name="phoneNumber"
            onChange={handleChange}
            value={values.phoneNumber}
            onBlur={handleBlur}
            errorText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ""}
          />{" "}
          <OutsourcingInput
            label="Your email address"
            placeholder="Enter email address"
            name="email"
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            errorText={touched.email && errors.email ? errors.email : ""}
          />
        </div>
        <div className={styles.fluidWrap}>
          <Textarea
            rows="9"
            textareaText="Ask us what you need to know"
            textareaLabel="Message"
            name={"message"}
            onChange={handleChange}
            value={values.message}
            onBlur={handleBlur}
            errorText={touched.message && errors.message ? errors.message : ""}
          />
        </div>
        <div className={styles.buttonWrap}>
          <OutsourcingButton className={styles.submitButton}>{loading ? "SENDING..." : "SEND"}</OutsourcingButton>
        </div>
      </form>
    </main>
  );
};

export { OutsourcingContact };
