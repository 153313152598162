import React from "react";
import styles from "./styles.module.css";

const Card = ({ src, title, description, work }) => {
  return (
    <div className={styles.tC}>
      <img src={src} />
      <h5>{title}</h5>
      <div>
        <p className={styles.greyText}>{description}</p>
        <span className={styles.blueText}>{work}</span>
      </div>
    </div>
  );
};

export { Card };
