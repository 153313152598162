import {
  alex,
  chisom,
  francis,
  avater,
  rachel,
  nneka,
  godson,
  interswitch,
  teneceLogo,
  smile,
  stanbic,
  taj,
  xend,
  wicryptLogo,
} from "../../../assets/images";

export const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 2,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
  },
};

export const mobileSliderBoxStyle = {
  display: "flex",
  width: "100%",
  background: "#F8F8F8",
  alignItems: "center",
  height: "100%",
};
export const jsonDataMobileSlider = [
  {
    icon: teneceLogo,
  },
  {
    icon: interswitch,
  },
  {
    icon: smile,
  },
  {
    icon: stanbic,
  },
  {
    icon: taj,
  },
  {
    icon: xend,
  },
  {
    icon: wicryptLogo,
  },
];

export const jsonDataSliderOne = [
  {
    imgSrc: `${alex}`,
    role: " DotNet Developer",
    name: "Alex Ogbuike",
    workPlace: "Eminent Software Technologies",
  },
  {
    imgSrc: `${chisom}`,
    role: "Senior Software Developer,",
    name: "Chisom Eke",
    workPlace: "StanbicIBTC",
  },
  {
    imgSrc: `${francis}`,
    role: "Backend Developer",
    name: "Francis Ogbonna",
    workPlace: "StanbicIBTC",
  },
  {
    imgSrc: `${rachel}`,
    role: "Software Developer",
    name: "Rachel Okorie",
    workPlace: "Tenece Professional Services",
  },
  {
    imgSrc: `${nneka}`,
    role: "Software Developer",
    name: "Anaedobe Lynda",
    workPlace: "Tenece Professional Services",
  },
  {
    imgSrc: `${godson}`,
    role: "Senior Product Manager",
    name: "Godson Njoku",
    workPlace: "Smile Technologies LTD",
  },
];

export const jsonDataSliderTwo = [
  {
    src: `${avater}`,
    role: "Partner, Eminent Technology.",
    name: "Bola Otusheso",
    des: "I wanted to share some positive feedback regarding our development team. Alex has been performing admirably thus far. As we work towards meeting a crucial deadline for one of our key clients, I have every confidence in Alex's abilities to deliver. The progress made so far is highly encouraging.",
  },
  {
    src: `${avater}`,
    role: "Head Business Technology, Interswitch",
    name: "Ushahemba Ukange",
    des: "We rely on Hubly for sourcing exceptional talent to meet our ever-evolving technology needs. They consistently deliver skilled IT professionals who understand the unique challenges of the financial sector. Hubly's partnership has streamlined our hiring process and ensured we always have the right people on board to drive our digital transformation.",
  },
  {
    src: `${avater}`,
    role: "Assistant Vice President , Enterprise Solutions.",
    name: "Nelson Iheuko",
    des: "Maintaining regulatory compliance is paramount in the financial sector, and Hubly understands this better than anyone. They've consistently provided us with compliance experts who have made a significant impact on our risk management and governance practices. Hubly is our trusted partner in ensuring we adhere to industry standards.",
  },
  {
    src: `${avater}`,
    role: "Executive Director, Genesys Tech Hub.",
    name: "Nnamdi Anika",
    des: "Hubly has been a game-changer for Dev Studio. Their ability to source top-tier talent in every sector is unparalleled. The professionals they've connected us with have proven to be invaluable assets to our team. Hubly's expertise and seamless process have helped us excel in a highly competitive industry.",
  },
];

export const sliderBoxStyleTwo = {
  display: "flex",
  width: "100%",
  background: "#F6FCF8",
};

export const multiWrap = {
  width: "97%",
  margin: "auto",
};

export const mainContentWrap = {
  display: "flex",
  flexDirection: "column",
  marginBlock: "10px",
  marginInlineStart: "40px",
  width: "83%",
};

export const cardImg = {
  objectFit: "contain",
  marginInlineEnd: "12px",
  height: "80px",
  width: "80x",
};

export const cardtitle = {
  fontFamily: "Segoe UI",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "24px",
  color: "#191919",
};
export const personalData = {
  height: "43%",
  display: "flex",
  alignItems: "center",
  marginBlockStart: "20px",
};
export const nameWrap = {
  paddingBlock: "15px",
};
export const cardRole = {
  fontFamily: "Segoe UI",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "22px",
  color: "#CFCFCF",
};

export const sliderOnecontainer = {
  border: "1px solid #EEEEEE",
  borderRadius: "10px",
  height: "325px",
  width: "328px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  background: "#fff",
};

export const cusImg = {
  height: "200px",
  width: "100%",
  objectFit: "contain",
};

export const title = {
  fontFamily: "Segoe UI",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "24px",
  marginBlock: "0",
  color: "#191919",
  paddingBlock: "18px 8px",
};

export const grey = {
  fontFamily: "Segoe UI",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#BABABA",
  margin: "0",
};

export const sliderBoxStyle = {
  background: "white",
  width: "100%",
};

export const blueText = {
  color: "#009CEB",
  fontFamily: "Segoe UI",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  margin: "0",
};

export const manner = {
  autoSliding: { interval: "3s" },
  duration: "2s",
};

export const buttonSetting = {
  placeOn: "middle-inside",
  hoverEvent: true,
  style: {
    left: {
      height: "50px",
      width: "50px",
      color: "#929393",
      background: "rgba(225, 228, 232, 0.8)",
      borderRadius: "50%",
    },
    right: {
      height: "50px",
      width: "50px",
      color: "#929393",
      background: "rgba(225, 228, 232, 0.8)",
      borderRadius: "50%",
    },
  },
};
