const exists = async (email, phoneNumber, link, sheet) => {
  const number = numberFormatter(phoneNumber);
  let info;
  const emailFetch = await fetch(`${link}/search?email=${email}&sheet=${sheet}`);
  const phoneFetch = await fetch(`${link}/search?phoneNumber=${number}&sheet=${sheet}`);
  const isEmail = await emailFetch.json();
  const isPhone = await phoneFetch.json();
  let isExist = isEmail.length > 0 || isPhone.length > 0;
  if (isExist && isEmail.length > 0 && isPhone.length > 0) {
    info = "email & phone number";
  } else if (isExist && isPhone.length > 0) {
    info = "phone number";
  } else if (isExist && isEmail.length > 0) {
    info = "email";
  }
  return { info, isExist };
};
export default exists;

export const emailExists = async (email, link) => {
  const emailFetch = await fetch(`${link}/search?email=${email}`);
  const isEmail = await emailFetch.json();
  if (isEmail.length > 0) {
    return "email already exists";
  }
};

const numberFormatter = (number) => {
  if (number.startsWith("0")) {
    let num = number.replace("0", "+234");
    return num;
  }
  return number;
};
