import React, { useEffect } from "react";
import { Cancel, ErrorIcon, SuccessIcon, WarningIcon } from "../../assets";

import styles from "./toast.module.css";

const Toast = ({ toastState, message, setDisplayToast }) => {
  useEffect(() => {
    setTimeout(() => {
      setDisplayToast(false);
    }, 4000);
  }, [setDisplayToast]);

  return (
    <div
      className={`${styles.container} ${
        toastState === "error" ? styles["container-error"] : toastState === "warning" ? styles["container-warning"] : ""
      }`}
    >
      <div>
        <span className="me-2">
          {toastState === "error" ? (
            <ErrorIcon className={styles.error} />
          ) : toastState === "warning" ? (
            <WarningIcon className={styles.warning} />
          ) : (
            <SuccessIcon className={styles.checked} />
          )}
        </span>
        <span className="ms-2 me-4 fw-bold">{message}</span>
        <span>
          <Cancel className={`${styles.close}`} onClick={() => setDisplayToast(false)} />
        </span>
      </div>
      <div
        className={`${styles.line} ${
          toastState === "warning" ? styles["line-warning"] : toastState === "error" ? styles["line-error"] : ""
        }`}
      ></div>
    </div>
  );
};

export { Toast };
